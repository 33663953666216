import Entity from '../entity'

export default class FieldMetadata extends Entity<number> {
    property: string;
    description: string;
    section: number | null;
    color: string;
    number: number | null;
    positionType: number | null;
    lookUpType: number;
    categoryId: number;
}
