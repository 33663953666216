import Entity from '../entity'

export default class Contact extends Entity<number> {
    firstName: string;
    lastName: string;
    emailAddress: string;
    mobilePhone: string;
    landline: string;
    signature: string;
    uploadedSignature: any;
    note: string;
}
