import {Store, Module, ActionContext} from 'vuex'
import ListModule from '.././list-module'
import ListState from '.././list-state'
import FieldMetadata from '../../entities/jobcontext/fieldmetadata'
import FieldMetadataVm from '../../entities/jobcontext/fieldmetadatavm'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import LookUp from "@/store/entities/lookup";

interface FieldMetadataState extends ListState<FieldMetadataVm> {
    editFieldMetadata: FieldMetadata,
}

class FieldMetadataModule extends ListModule<FieldMetadataState, any, FieldMetadataVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<FieldMetadataVm>(),
        loading: false,
        editNode: new FieldMetadata()
    }
    actions = {
        async getAll(context: ActionContext<FieldMetadataState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/field-metadata', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<FieldMetadataVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async update(context: ActionContext<FieldMetadataState, any>, payload: any) {
            await Ajax.put('/api/field-metadata', payload.data);
        },
        async get(context: ActionContext<FieldMetadataState, any>, payload: any) {
            let response = await Ajax.get('api/field-metadata/' + payload.data);
            return response.data.result as FieldMetadata;
        },
        async sectionNumbers(context: ActionContext<FieldMetadataState, any>) {
            let response = await Ajax.get('/api/dictionary/section-number');
            return response.data.result as Array<LookUp>;
        },
        async positionTypes(context: ActionContext<FieldMetadataState, any>) {
            let response = await Ajax.get('api/dictionary/position-type');
            return response.data.result as Array<LookUp>;
        },
        async lookUp(context: ActionContext<FieldMetadataState, any>) {
            let response = await Ajax.get('/api/dictionary/field');
            return response.data.result as Array<LookUp>;
        }
    };
    mutations = {
        setCurrentPage(state: FieldMetadataState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: FieldMetadataState, pageSize: number) {
            state.pageSize = pageSize;
        },
        edit(state: FieldMetadataState, fieldMetadata: FieldMetadata) {
            state.editFieldMetadata = fieldMetadata;
        }
    }
}

const fieldMetadataModule = new FieldMetadataModule();
export default fieldMetadataModule;
