import {Store, Module, ActionContext} from 'vuex'
import ListModule from '.././list-module'
import ListState from '.././list-state'
import TradeTransportLocation from '../../entities/countrycontext/tradetransportlocation'
import TradeTransportLocationVm from '../../entities/countrycontext/tradetransportlocationvm'
import Ajax from '../../../lib/ajax'
import ListMutations from '.././list-mutations'
import PageResult from "../../entities/page-result";
import LookUp from "../../entities/lookup";
import Vue from "vue";

interface TradeTransportLocationState extends ListState<TradeTransportLocationVm> {
    editTradeTransportLocation: TradeTransportLocation,
}

class TradeTransportLocationMutations extends ListMutations<TradeTransportLocation> {

}

class TradeTransportLocationModule extends ListModule<TradeTransportLocationState, any, TradeTransportLocationVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<TradeTransportLocationVm>(),
        loading: false,
        editTradeTransportLocation: new TradeTransportLocation()
    }
    actions = {
        async getAll(context: ActionContext<TradeTransportLocationState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/trade-transport-location/get', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<TradeTransportLocationVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<TradeTransportLocationState, any>, payload: any) {
            await Ajax.post('/api/trade-transport-location', payload.data);
        },
        async update(context: ActionContext<TradeTransportLocationState, any>, payload: any) {
            await Ajax.put('/api/trade-transport-location', payload.data);
        },
        async delete(context: ActionContext<TradeTransportLocationState, any>, payload: any) {
            await Ajax.delete('/api/trade-transport-location?Id=' + payload.data.id);
        },
        async get(context: ActionContext<TradeTransportLocationState, any>, payload: any) {
            let response = await Ajax.get('/api/trade-transport-location/' + payload.data);
            return response.data.result as TradeTransportLocation;
        },
        async keyWordLookUp(context: ActionContext<TradeTransportLocationState, any>, payload: any) {
            let response = await Ajax.get('api/dictionary/trade-transport-location', {params: payload});
            return response.data.result as Array<LookUp>;
        },
        async uploadFile(context: ActionContext<TradeTransportLocationState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.put('/api/trade-transport-location/sync', payload.data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                vm.$Modal.success({title: 'Successfully synced'})
            });
        }
    };
    mutations = {
        setCurrentPage(state: TradeTransportLocationState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: TradeTransportLocationState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: TradeTransportLocationState, tradeTransportLocation: TradeTransportLocation) {
            state.editTradeTransportLocation = tradeTransportLocation;
        }
    }
}

const tradeTransportLocationModule = new TradeTransportLocationModule();
export default tradeTransportLocationModule;
