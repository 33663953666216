import Entity from "@/store/entities/entity";
import EmailTemplateDynamicProperty from "@/store/entities/notificationcontext/email-template-dynamic-property";

export default class EmailTemplate extends Entity<number> {
    body: string;
    subject: string;
    name: string;
    code: string;
    boundCompanies: Array<string>;
    dynamicProperties: Array<EmailTemplateDynamicProperty>;

    constructor(){
        super();
        this.dynamicProperties = new Array<EmailTemplateDynamicProperty>();
    }
}
