
























import {Component} from "vue-property-decorator";
import AbpBase from "@/lib/abpbase";

@Component
export default class ErrorPage extends AbpBase {

}
