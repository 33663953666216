import {Store, Module, ActionContext} from 'vuex'
import ListModule from '.././list-module'
import ListState from '.././list-state'
import ISOCode from '../../entities/containercontext/isocode'
import ISOCodeVm from '../../entities/containercontext/isocodevm'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '.././list-mutations'
import ISOCodeLookUp from "@/store/entities/containercontext/isocodelookup";
import Vue from "vue";

interface ISOCodeState extends ListState<ISOCodeVm> {
    editISOCode: ISOCode,
}

class UserMutations extends ListMutations<ISOCode> {

}

class ISOCodeModule extends ListModule<ISOCodeState, any, ISOCodeVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<ISOCodeVm>(),
        loading: false,
        editISOCode: new ISOCode()
    }
    actions = {
        async getAll(context: ActionContext<ISOCodeState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/iso-code/get', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<ISOCodeVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        }, async create(context: ActionContext<ISOCodeState, any>, payload: any) {
            await Ajax.post('/api/iso-code', payload.data);
        }, async update(context: ActionContext<ISOCodeState, any>, payload: any) {
            await Ajax.put('/api/iso-code', payload.data);
        }, async delete(context: ActionContext<ISOCodeState, any>, payload: any) {
            await Ajax.delete('api/iso-code?Id=' + payload.data.id);
        }, async get(context: ActionContext<ISOCodeState, any>, payload: any) {
            let response = await Ajax.get('api/iso-code/' + payload.data);
            return response.data.result as ISOCode;
        }, async filteredLookUp(context: ActionContext<ISOCodeState, any>, payload: any) {
            let response = await Ajax.get('/api/iso-code/look-up', {params: payload});
            return response.data.result as Array<ISOCodeLookUp>;
        }, async lookUp(context: ActionContext<ISOCodeState, any>) {
            let response = await Ajax.get('/api/dictionary/iso-code');
            return response.data.result as Array<ISOCodeLookUp>;
        },
        async uploadFile(context: ActionContext<ISOCodeState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.put('/api/iso-code/sync', payload.data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                vm.$Modal.success({title: 'Successfully synced'})
            });
        }
    };
    mutations = {
        setCurrentPage(state: ISOCodeState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: ISOCodeState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: ISOCodeState, isoCode: ISOCode) {
            state.editISOCode = isoCode;
        }
    }
}

const isoCodeModule = new ISOCodeModule();
export default isoCodeModule;
