import Entity from '../entity'

export default class CustomerShippingOrder extends Entity<number> {
    notes: string;
    originId: number | null;
    destinationId: number | null;
    supplierId: string;
    consigneeId: number | null;
    clientId: number;
    customSupplierName: string;
    isCustomSupplier: boolean;
    customConsigneeName: string;
    isCustomConsignee: boolean;
    incotermId: number | null;
    readyDate: Date;
    hasHazardousGoods: boolean;
    po: string;
    shippingMethod: number | null;
    status: number;
}
