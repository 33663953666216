import Entity from '../entity'
export default class Node extends Entity<number> {
    name: string;
    note: string;
    documents: Array<number>;
    actions: Array<number>;
    fields: Array<number>;
    order: string;
    isAvailableForClient: boolean;
}
