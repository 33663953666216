import {Route} from "vue-router";

declare global {
    interface RouterMeta {
        title: string;
    }

    interface Router {
        path: string;
        name: string;
        icon?: string;
        permission?: string;
        meta?: RouterMeta;
        component: any;
        toShow: boolean;
        children?: Array<Router>;
        params?: any
    }

    interface System {
        import(request: string): Promise<any>
    }

    let System: System;
}
import home from '../views/home/home.vue'
import main from '../views/main.vue'
import documentRequest from '../views/setting/jobcontext/jobdocumentrequestcontext/client-jobdocumentrequest.vue'

export const locking = {
    path: '/locking',
    name: 'locking',
    component: () => import('../components/lockscreen/components/locking-page.vue')
};
export const loginRouter: Router = {
    path: '/',
    name: 'login',
    toShow: true,
    meta: {
        title: 'LogIn'
    },
    component: () => import('../views/login.vue')
};
export const ForgetPasswordRouter: Router = {
    path: '/forgot-password',
    name: 'forgotPassword',
    toShow: true,
    meta: {
        title: 'Forget Password'
    },
    component: () => import('../views/forgot-password.vue')
};
export const ResetPasswordRouter: Router = {
    path: '/reset-password',
    name: 'resetPassword',
    toShow: true,
    meta: {
        title: 'Reset Password'
    },
    component: () => import('../views/reset-password.vue')
};
export const documentRequestRouter: Router ={
    path: '/document-request/:requestIdentifier',
    name: 'request',
    toShow: true,
    meta: {
        title: 'DocumentRequest'
    },
    component: documentRequest
}

export const otherRouters: Router = {
    path: '/main',
    name: 'main',
    toShow: true,
    permission: '',
    meta: {title: 'Manage menu'},
    component: main,
    children: [
        {
            path: 'home',
            meta: {title: 'HomePage'},
            toShow: true,
            name: 'home',
            component: () => import('../views/home/home.vue')
        }
    ]
}
export const appRouters: Array<Router> = [{
    path: '/setting',
    name: 'setting',
    permission: '',
    meta: {title: 'Manage menu'},
    icon: 'ios-settings-outline',
    component: main,
    toShow: true,
    children: [
        {
            path: 'user',
            permission: 'Pages.Users',
            meta: {title: 'Users'},
            name: 'user',
            toShow: true,
            component: () => import('../views/setting/user/user.vue')
        },
        {
            path: 'role',
            permission: 'Pages.Roles',
            meta: {title: 'Roles'},
            name: 'role',
            toShow: true,
            component: () => import('../views/setting/role/role.vue')
        },
        {
            path: 'tenant',
            permission: 'Pages.Tenants',
            meta: {title: 'Tenants'},
            name: 'tenant',
            toShow: true,
            component: () => import('../views/setting/tenant/tenant.vue')
        },
        {
            path: 'notifications',
            permission: 'Pages.Notifications',
            meta: {title: 'Notifications'},
            name: 'notification',
            toShow: true,
            component: () => import('../views/setting/notificationcontext/notification/notofocation-list.vue')
        }
    ]
},
    {
        path: '/crm',
        name: 'crm',
        meta: {title: 'Company menu'},
        icon: 'ios-briefcase-outline',
        permission: '',
        toShow: true,
        component: main,
        children: [
            {
                path: 'category',
                meta: {title: 'Categories'},
                name: 'category',
                permission: 'Pages.Categories',
                toShow: true,
                component: () => import('../views/setting/companycontext/category/category.vue')
            },
            {
                path: 'department',
                meta: {title: 'Departments'},
                name: 'department',
                permission: 'Pages.Departments',
                toShow: true,
                component: () => import('../views/setting/companycontext/department/department.vue')
            },
            {
                path: 'contact',
                meta: {title: 'Contacts'},
                name: 'contact',
                permission: 'Pages.Contacts',
                toShow: true,
                component: () => import('../views/setting/companycontext/contact/contact.vue')
            },
            {
                path: 'company',
                meta: {title: 'Companies'},
                permission: 'Pages.Companies',
                name: 'company',
                toShow: true,
                component: () => import('../views/setting/companycontext/company/company.vue'),
            },
            {
                path: 'company/create',
                name: 'create-company',
                meta: {
                    title: 'create company'
                },
                toShow: false,
                component: () => import('../views/setting/companycontext/company/create-company.vue')
            }, {
                path: 'company/edit/:companyId',
                name: 'edit-company',
                meta: {
                    title: 'edit company'
                },
                toShow: false,
                component: () => import('../views/setting/companycontext/company/edit-company.vue')
            }, {
                path: 'contact/edit/:contactId',
                name: 'edit-contact',
                meta: {
                    title: 'edit contact'
                },
                toShow: false,
                component: () => import('../views/setting/companycontext/contact/edit-contact.vue')
            }, {
                path: 'address/form/:companyId/:addressId',
                name: 'address-form',
                meta: {
                    title: 'edit address'
                },
                toShow: false,
                component: () => import('../views/setting/companycontext/address/address-form.vue')
            }]
    },
    {
        path: '/logistic',
        name: 'logistic',
        permission: '',
        meta: {title: 'Logistic'},
        icon: 'ios-boat-outline',
        component: main,
        toShow: true, children: [
            {
                path: 'order',
                meta: {title: 'Shipping orders'},
                name: 'order',
                permission: 'Pages.ShippingOrder',
                toShow: true,
                component: () => import('../views/setting/OrderContext/Order/Order.vue')
            },
            {
                path: 'admin-order/:orderId',
                meta: {title: 'Orders'},
                name: 'admin-order',
                toShow: false,
                component: () => import('../views/setting/OrderContext/Order/OrderAdminForm.vue')
            },
            {
                path: 'document',
                permission: 'Pages.Documents',
                meta: {title: 'Documents'},
                name: 'document',
                toShow: true,
                component: () => import('../views/setting/jobcontext/document/document.vue')
            },
            {
                path: 'tag',
                permission: 'Pages.Tags',
                meta: {title: 'Tags'},
                name: 'tag',
                toShow: true,
                component: () => import('../views/setting/jobcontext/tagcontext/tag.vue')
            },
            {
                path: 'action',
                permission: 'Pages.Actions',
                meta: {title: 'Actions'},
                name: 'action',
                toShow: true,
                component: () => import('../views/setting/jobcontext/action/action.vue')
            },
            {
                path: 'node',
                permission: 'Pages.Nodes',
                meta: {title: 'Nodes'},
                name: 'node',
                toShow: true,
                component: () => import('../views/setting/jobcontext/node/node.vue')
            },
            {
                path: 'jobTemplate',
                meta: {title: 'Job templates'},
                permission: 'Pages.JobTemplates',
                name: 'jobTemplate',
                toShow: true,
                component: () => import('../views/setting/jobcontext/jobtemplate/jobtemplate.vue')
            },
            {
                path: 'job',
                meta: {title: 'Job'},
                name: 'job',
                permission: 'Pages.Job',
                toShow: true,
                component: () => import('../views/setting/jobcontext/job/job.vue')
            },
            {
                path: 'job-overview',
                meta: {title: 'Job Overview'},
                name: 'job-overview',
                permission: 'Pages.JobOverview',
                toShow: true,
                component: () => import('../views/setting/jobcontext/job/job-overview.vue')
            },
            {
                path: 'job-form/:jobId',
                meta: {title: 'JobForm'},
                name: 'job-form',
                toShow: false,
                component: () => import('../views/setting/jobcontext/job/job-form.vue')
            },
            {
                path: 'job-audit/:jobId',
                meta: {title: 'JobAudit'},
                name: 'job-audit',
                toShow: false,
                component: () => import('../views/setting/jobcontext/jobauditing/jobauditing.vue')
            },
            {
                path: 'job-client-form/:jobId',
                meta: {title: 'JobClientForm'},
                name: 'job-client-form',
                toShow: false,
                component: () => import('../views/setting/jobcontext/job/job-client-form.vue')
            },
            {
                path: 'field-metadata',
                meta: {title: 'Field Metadata'},
                name: 'field-metadata',
                permission: 'Pages.FieldsMetadata',
                toShow: true,
                component: () => import('../views/setting/jobcontext/fieldmetadata/fieldmetadata.vue')
            }
        ]
    },
    {
        path: '/lookup',
        name: 'lookup',
        permission: '',
        meta: {title: 'Look up menu'},
        icon: 'ios-list-box-outline',
        component: main,
        toShow: true,
        children: [
            {
                path: 'country',
                permission: 'Pages.Countries',
                meta: {title: 'Countries'},
                name: 'country',
                toShow: true,
                component: () => import('../views/setting/countrycontext/country/country.vue')
            },
            {
                path: 'subdivisiontype',
                permission: 'Pages.SybdivisionTypes',
                meta: {title: 'Subdivision types'},
                name: 'subdivisiontype',
                toShow: true,
                component: () => import('../views/setting/countrycontext/subdivisiontype/subdivisiontype.vue')
            },
            {
                path: 'subdivision',
                permission: 'Pages.Subdivisions',
                meta: {title: 'Subdivisions'},
                name: 'subdivision',
                toShow: true,
                component: () => import('../views/setting/countrycontext/subdivision/subdivision.vue')
            },
            {
                path: 'tradetransportlocation',
                permission: 'Pages.TradeTransportLocations',
                meta: {title: 'UN/LOCODE'},
                name: 'tradetransportlocation',
                toShow: true,
                component: () => import('../views/setting/countrycontext/tradetransportlocation/tradetransportlocation.vue')
            },
            {
                path: 'vesseltype',
                meta: {title: 'Vessel types'},
                permission: 'Pages.VesselTypes',
                name: 'vesseltype',
                toShow: true,
                component: () => import('../views/setting/vesseltype/vesseltype.vue')
            },
            {
                path: 'vessel',
                permission: 'Pages.Vessels',
                meta: {title: 'Vessels'},
                name: 'vessel',
                toShow: true,
                component: () => import('../views/setting/vessel/vessel.vue')
            },
            {
                path: 'airlinecode',
                permission: 'Pages.AirlineCodes',
                meta: {title: 'Airline Codes'},
                name: 'airlinecode',
                toShow: true,
                component: () => import('../views/setting/airlinecode/airlinecode.vue')
            },
            {
                path: 'vehicletype',
                meta: {title: 'Vehicle types'},
                name: 'vehicletype',
                permission: 'Pages.VehicleTypes',
                toShow: true,
                component: () => import('../views/setting/vehicletype/vehicletype.vue')
            },
            {
                path: 'packagetype',
                meta: {title: 'Package types'},
                name: 'packagetype',
                permission: 'Pages.PackageTypes',
                toShow: true,
                component: () => import('../views/setting/packagetype/packagetype.vue')
            },
            {
                path: 'servicelevel',
                meta: {title: 'Service levels'},
                name: 'servicelevel',
                permission: 'Pages.ServiceLevels',
                toShow: true,
                component: () => import('../views/setting/servicelevel/servicelevel.vue')
            },
            {
                path: 'incoterm',
                meta: {title: 'Incoterms'},
                name: 'incoterm',
                permission: 'Pages.Incoterms',
                toShow: true,
                component: () => import('../views/setting/incoterm/incoterm.vue')
            },
            {
                path: 'containersize',
                meta: {title: 'Container sizes'},
                name: 'containersize',
                permission: 'Pages.ContainerSizes',
                toShow: true,
                component: () => import('../views/setting/containercontext/containersize/containersize.vue')
            },
            {
                path: 'containertype',
                meta: {title: 'Container types'},
                name: 'containertype',
                permission: 'Pages.ContainerTypes',
                toShow: true,
                component: () => import('../views/setting/containercontext/containertype/containertype.vue')
            },
            {
                path: 'isocode',
                meta: {title: 'ISO codes'},
                name: 'isocode',
                permission: 'Pages.ISOCodes',
                toShow: true,
                component: () => import('../views/setting/containercontext/isocode/isocode.vue')
            },
            {
                path: 'container',
                meta: {title: 'Containers'},
                name: 'container',
                permission: 'Pages.Containers',
                toShow: true,
                component: () => import('../views/setting/containercontext/container/container.vue')
            },
            {
                path: 'emailTemplate',
                meta: {title: 'Email templates'},
                name: 'emailTemplate',
                permission: 'Pages.EmailTemplates',
                toShow: true,
                component: () => import('../views/setting/notificationcontext/emailtemplate/emailtemplate.vue')
            },
            {
                path: 'currency',
                meta: {title: 'Currencies'},
                name: 'currency',
                permission: 'Pages.Currencies',
                toShow: true,
                component: () => import('../views/setting/currency/currency.vue')
            }
        ]
    }]

export const routers = [
    documentRequestRouter,
    loginRouter,
    ForgetPasswordRouter,
    locking,
    ResetPasswordRouter,
    ...appRouters,
    otherRouters
];
