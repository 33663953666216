import Entity from '../entity'
export default class Company extends Entity<number> {
    name: string;
    industry: string;
    registrationNumber: string;
    isTaxPayer: boolean | null;
    webSite: string;
    email: string;
    phoneNumber: string
    isEnabled: boolean | null;
    parentId: number | null;
    categories: Array<number>;
    logo: any;
    note: string;
    abn: string;
    uploadedLogo: any;

    constructor() {
        super();
    }
}
