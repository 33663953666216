import Entity from '../entity'

export default class CompanyInstruction extends Entity<number> {
    companyId: number;
    name: string
    instructionType: number;
    transportDeliveryTime: string;
    transportDeliveryVehicleTypeId: number;
    destinationAgentId: number;
    transportCompanyDestinationId: number;
    originAgentId: number;
    cargoReportingAgentId: number;
    destinationCustomsBrokerId: number;
    transportDeliveryInstructions: string;
    deliveryAddressId: number;
    transportCollectionTime: string;
    requireInsurance: boolean;
    transportVehicleTypeId: number;
    transportInstructions: string;
    transportCompanyId: number;
    originCustomBrokerId: number;
    collectionAddressId: number;
    consigneeId: number;
    supplierId: number;
}
