import {Store, Module, ActionContext} from 'vuex'
import ListModule from '.././list-module'
import ListState from '.././list-state'
import Ajax from '../../../lib/ajax'
import PageResult from "../../entities/page-result";
import JobOverview from "@/store/entities/jobcontext/joboverview";


interface JobOverviewState extends ListState<JobOverview> {
    jobId: number
}

class JobOverviewModule extends ListModule<JobOverviewState, any, JobOverview> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<JobOverview>(),
        loading: false,
        editAction: new JobOverview(),
        jobId: 0
    }
    actions = {
        async getAll(context: ActionContext<JobOverviewState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/job/overview',  payload.data);
            context.state.loading = false;
            context.state.totalCount = 1;
            context.state.list = response.data.result;
        },
        async getJobFieldsMetadata(context: ActionContext<JobOverviewState, any>, payload: any){
            let response = await Ajax.get('/api/job/overview/' + payload);
            return response.data.result;
        }
    };
    mutations = {
        setCurrentPage(state: JobOverviewState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: JobOverviewState, pageSize: number) {
            state.pageSize = pageSize;
        },
        edit(state: JobOverviewState, id: number) {
            state.jobId = id;
        }
    }
}

const jobOverviewModule = new JobOverviewModule();
export default jobOverviewModule;
