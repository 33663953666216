



























import { Component, Vue,Inject,Prop,Emit } from 'vue-property-decorator';
import AbpBase from '../../../lib/abpbase'
@Component
export default class extends AbpBase {
    name:string='sidebarMenuShrink';
    @Prop({type:Array}) menuList:Array<any>;
    @Prop({default:'white'}) iconColor:string;
    @Prop({default:'darck'}) menuTheme:string;
    @Emit('on-change') changeMenu(active:string){

    }
    itemTitle(item:any){
        return this.L(item.meta.title);
    }
}
