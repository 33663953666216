import {Store, Module, ActionContext} from 'vuex'
import ListModule from '.././list-module'
import ListState from '.././list-state'
import EmailTemplate from '../../entities/notificationcontext/emailtemplate'
import EmailTemplateVm from '../../entities/notificationcontext/emailtemplatevm'
import Ajax from '../../../lib/ajax'
import ListMutations from '.././list-mutations'
import PageResult from "../../entities/page-result";
import LookUp from "../../entities/lookup";
import JobCompaniesLookUp from "@/store/entities/notificationcontext/jobCompaniesLookUp";
import EmailTemplateDynamicProperty from "@/store/entities/notificationcontext/email-template-dynamic-property";

interface EmailTemplateState extends ListState<EmailTemplateVm> {
    editEmailTemplate: EmailTemplate,
}

class EmailTemplateMutations extends ListMutations<EmailTemplate> {
}

class EmailTemplateModule extends ListModule<EmailTemplateState, any, EmailTemplateVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<EmailTemplateVm>(),
        loading: false,
        editEmailTemplate: new EmailTemplate()
    }
    actions = {
        async getAll(context: ActionContext<EmailTemplateState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/email-template/get', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<EmailTemplateVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<EmailTemplateState, any>, payload: any) {
            await Ajax.post('/api/email-template', payload.data);
        },
        async update(context: ActionContext<EmailTemplateState, any>, payload: any) {
            await Ajax.put('/api/email-template', payload.data);
        },
        async delete(context: ActionContext<EmailTemplateState, any>, payload: any) {
            await Ajax.delete('/api/email-template?Id=' + payload.data.id);
        },
        async get(context: ActionContext<EmailTemplateState, any>, payload: any) {
            let response = await Ajax.get('/api/email-template/' + payload.data);
            return response.data.result as EmailTemplate;
        },
        async lookUp(context: ActionContext<EmailTemplateState, any>) {
            let response = await Ajax.get('api/email-template/look-up');
            return response.data.result as Array<LookUp>;
        },
        async getJobAvailableProperties(context: ActionContext<EmailTemplateState, any>) {
            let response = await Ajax.get('api/email-template/available-job-properties');
            return response.data.result as Array<LookUp>;
        },
        async getJobsCompanies(context: ActionContext<EmailTemplateState, any>) {
            let response = await Ajax.get('api/dictionary/job-company-fields');
            return response.data.result as Array<JobCompaniesLookUp>;
        },
        async getDynamicProperties(context: ActionContext<EmailTemplateState, any>, payload: any) {
            let response = await Ajax.get('api/email-template/dynamic-properties/' + payload);
            return response.data.result;
        }
    };
    mutations = {
        setCurrentPage(state: EmailTemplateState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: EmailTemplateState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: EmailTemplateState, emailTemplate: EmailTemplate) {
            state.editEmailTemplate = emailTemplate;
        }
    }
}

const emailTemplateModule = new EmailTemplateModule();
export default emailTemplateModule;
