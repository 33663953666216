import Entity from '../entity'
import JobTemplateNode from "@/store/entities/jobcontext/jobtemplatenode";

export default class JobTemplate extends Entity<number> {
    name: string;
    nodes: Array<JobTemplateNode>;
    fields: Array<number>;
    customFields: Array<number>;

    constructor() {
        super();
        this.nodes = new Array<JobTemplateNode>();
        this.fields = new Array<number>();
        this.customFields = new Array<number>();
    }
}
