import {Store, Module, ActionContext} from 'vuex'
import ListModule from './list-module'
import ListState from './list-state'
import ServiceLevel from '../entities/servicelevel'
import ServiceLevelLookUp from "../entities/servicelevellookup";
import Ajax from '../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from './list-mutations'

interface ServiceLevelState extends ListState<ServiceLevel> {
    editServiceLevel: ServiceLevel,
}

class ServiceLevelMutations extends ListMutations<ServiceLevel> {

}

class ServiceLevelModule extends ListModule<ServiceLevelState, any, ServiceLevel> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<ServiceLevel>(),
        loading: false,
        editVesselLevel: new ServiceLevel()
    }
    actions = {
        async getAll(context: ActionContext<ServiceLevelState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('api/service-level/get', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<ServiceLevel>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<ServiceLevelState, any>, payload: any) {
            await Ajax.post('api/service-level', payload.data);
        },
        async update(context: ActionContext<ServiceLevelState, any>, payload: any) {
            await Ajax.put('api/service-level', payload.data);
        },
        async delete(context: ActionContext<ServiceLevelState, any>, payload: any) {
            await Ajax.delete('api/service-level?Id=' + payload.data.id);
        },
        async get(context: ActionContext<ServiceLevelState, any>, payload: any) {
            let response = await Ajax.get('api/service-level/' + payload.id);
            return response.data.result as ServiceLevel;
        },
        async lookUp(context: ActionContext<ServiceLevelState, any>, payload: any) {
            let response = await Ajax.get('api/service-level/look-up');
            return response.data.result as Array<ServiceLevelLookUp>;
        }
    };
    mutations = {
        setCurrentPage(state: ServiceLevelState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: ServiceLevelState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: ServiceLevelState, serviceLevel: ServiceLevel) {
            state.editServiceLevel = serviceLevel;
        }
    }
}

const serviceLevelModule = new ServiceLevelModule();
export default serviceLevelModule;