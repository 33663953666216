import {Store, Module, ActionContext} from 'vuex'
import ListModule from './list-module'
import ListState from './list-state'
import Vessel from '../entities/vessel'
import VesselVm from '../entities/vesselvm'
import Ajax from '../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from './list-mutations'
import Vue from "vue";
import LookUp from "@/store/entities/lookup";
import VesselHistoryVm from "@/store/entities/vesselhistoryvm";

interface VesselState extends ListState<VesselVm> {
    editVessel: Vessel
}

class VesselMutations extends ListMutations<Vessel> {

}

class VesselModule extends ListModule<VesselState, any, VesselVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<VesselVm>(),
        loading: false,
        editVessel: new Vessel()
    }
    actions = {
        async getAll(context: ActionContext<VesselState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/vessel/get', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<VesselVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<VesselState, any>, payload: any) {
            await Ajax.post('/api/vessel', payload.data);
        },
        async addName(context: ActionContext<VesselState, any>, payload: any) {
            await Ajax.post('/api/vessel/add-name', payload.data);
        },
        async update(context: ActionContext<VesselState, any>, payload: any) {
            await Ajax.put('/api/vessel', payload.data);
        },
        async delete(context: ActionContext<VesselState, any>, payload: any) {
            await Ajax.delete('/api/vessel?Id=' + payload.data.id);
        },
        async get(context: ActionContext<VesselState, any>, payload: any) {
            let response = await Ajax.get('api/vessel/' + payload.data);
            return response.data.result as Vessel;
        },
        async history(context: ActionContext<VesselState, any>, payload: any) {
            let response = await Ajax.get('api/vessel/vessel-history?vesselId=' + payload);
            return response.data.result as Array<VesselHistoryVm>;
        },
        async lookUp(context: ActionContext<VesselState, any>, payload: any) {
            let response = await Ajax.get('api/dictionary/vessel', {params: payload});
            return response.data.result as Array<LookUp>;
        },
        async uploadFile(context: ActionContext<VesselState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.put('api/vessel/sync', payload.data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                vm.$Modal.success({title: 'Successfully synced'})
            });
        }
    };
    mutations = {
        setCurrentPage(state: VesselState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: VesselState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: VesselState, vessel: Vessel) {
            state.editVessel = vessel;
        }
    }
}

const vesselModule = new VesselModule();
export default vesselModule;
