import {Store, Module, ActionContext} from 'vuex'
import ListModule from './list-module'
import ListState from './list-state'
import EntityChangeList from '../entities/auditingcontext/entitychangelist'
import Ajax from '../../lib/ajax'
import PageResult from "../entities/page-result";
import Country from "@/store/entities/country";
import EntityPropertyChange from "@/store/entities/auditingcontext/entitypropertychange";

interface AuditingState extends ListState<EntityChangeList> {
    details: Array<EntityPropertyChange>
}


class AuditingModule extends ListModule<AuditingState, any, EntityChangeList> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<EntityChangeList>(),
        loading: false,
        details: new Array<EntityPropertyChange>()
    }
    actions = {
        async getAll(context: ActionContext<AuditingState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/audit/entity-changes',  payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<EntityChangeList>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async getDetails(context: ActionContext<AuditingState, any>, payload: any){
            let response = await Ajax.get('/api/audit/entity-property-changes?entityChangeId=' +  payload);
            context.state.details = response.data.result as Array<EntityPropertyChange>;
        }
    };
    mutations = {
        setCurrentPage(state: AuditingState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: AuditingState, pageSize: number) {
            state.pageSize = pageSize;
        }
    }
}

const auditingModule = new AuditingModule();
export default auditingModule;
