import {Store, Module, ActionContext} from 'vuex'
import ListModule from '.././list-module'
import ListState from '.././list-state'
import Address from '../../entities/companycontext/address'
import AddressVm from '../../entities/companycontext/addressvm'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '.././list-mutations'
import LookUp from "@/store/entities/lookup";

interface AddressState extends ListState<AddressVm> {
    editAddress: Address,
}

class AddressMutations extends ListMutations<Address> {

}

class AddressModule extends ListModule<AddressState, any, AddressVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<AddressVm>(),
        loading: false,
        editAddress: new Address()
    }
    actions = {
        async getAll(context: ActionContext<AddressState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/company-address/get', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<AddressVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<AddressState, any>, payload: any) {
            await Ajax.post('/api/company-address', payload.data);
        },
        async update(context: ActionContext<AddressState, any>, payload: any) {
            await Ajax.put('/api/company-address', payload.data);
        },
        async delete(context: ActionContext<AddressState, any>, payload: any) {
            await Ajax.delete('api/company-address?Id=' + payload.data.id);
        },
        async get(context: ActionContext<AddressState, any>, payload: any) {
            let response = await Ajax.get('api/company-address/' + payload.data);
            return response.data.result as Address;
        },
        async lookUp(context: ActionContext<AddressState, any>, payload: any) {
            let response = await Ajax.get('api/dictionary/company-address?companyId=' + payload);
            return response.data.result as Array<LookUp>;
        },
        async unloadingLookUp(context: ActionContext<AddressState, any>, payload: any) {
            let response = await Ajax.get('api/dictionary/company-address-unloading?companyId=' + payload);
            return response.data.result as Array<LookUp>;
        }
    };
    mutations = {
        setCurrentPage(state: AddressState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: AddressState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: AddressState, address: Address) {
            state.editAddress = address;
        }
    }
}

const addressModule = new AddressModule();
export default addressModule;
