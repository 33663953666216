import {Store, Module, ActionContext} from 'vuex'
import ListModule from './list-module'
import ListState from './list-state'
import VehicleType from '../entities/vehicletype'
import Ajax from '../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from './list-mutations'
import LookUp from "@/store/entities/lookup";

interface VehicleTypeState extends ListState<VehicleType> {
    editVehicleType: VehicleType,
}

class VehicleTypeMutations extends ListMutations<VehicleType> {

}

class VehicleTypeModule extends ListModule<VehicleTypeState, any, VehicleType> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<VehicleType>(),
        loading: false,
        editVesselType: new VehicleType()
    }
    actions = {
        async getAll(context: ActionContext<VehicleTypeState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('api/vehicle-type/get', payload.data);
            context.state.loading = false;
            let page = reponse.data.result as PageResult<VehicleType>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<VehicleTypeState, any>, payload: any) {
            await Ajax.post('api/vehicle-type', payload.data);
        },
        async update(context: ActionContext<VehicleTypeState, any>, payload: any) {
            await Ajax.put('api/vehicle-type', payload.data);
        },
        async delete(context: ActionContext<VehicleTypeState, any>, payload: any) {
            await Ajax.delete('api/vehicle-type?Id=' + payload.data.id);
        },
        async get(context: ActionContext<VehicleTypeState, any>, payload: any) {
            let response = await Ajax.get('api/vehicle-type/' + payload.id);
            return response.data.result as VehicleType;
        },
        async lookUp(context: ActionContext<VehicleTypeState, any>, payload: any) {
            let response = await Ajax.get('api/dictionary/vehicle-type');
            return response.data.result as Array<LookUp>;
        }
    };
    mutations = {
        setCurrentPage(state: VehicleTypeState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: VehicleTypeState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: VehicleTypeState, vehicleType: VehicleType) {
            state.editVehicleType = vehicleType;
        }
    }
}

const vehicleTypeModule = new VehicleTypeModule();
export default vehicleTypeModule;
