import {ActionContext} from 'vuex'
import ListModule from './../list-module'
import ListState from './../list-state'
import Tag from '../../entities/jobcontext/tag'
import Ajax from '../../../lib/ajax'
import ListMutations from './../list-mutations'
import PageResult from "../../entities/page-result";
import LookUp from "@/store/entities/lookup";

interface TagState extends ListState<Tag> {
    tag: Tag,
}

class TagMutations extends ListMutations<Tag> {

}

class TagModule extends ListModule<TagState, any, Tag> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<Tag>(),
        loading: false,
        editTag: new Tag()
    }
    actions = {
        async getAll(context: ActionContext<TagState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/tags/get',  payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<Tag>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<TagState, any>, payload: any) {
            await Ajax.post('/api/tags', payload.data);
        },
        async update(context: ActionContext<TagState, any>, payload: any) {
            await Ajax.put('/api/tags', payload.data);
        },
        async delete(context: ActionContext<TagState, any>, payload: any) {
            await Ajax.delete('/api/tags?Id=' + payload.data.id);
        },
        async get(context: ActionContext<TagState, any>, payload: any) {
            let response = await Ajax.get('/api/tags/' + payload.data);
            return response.data.result as Tag;
        },
        async lookUp(context: ActionContext<TagState, any>, payload: any) {
            let response = await Ajax.get('api/dictionary/tags');
            return response.data.result as Array<LookUp>;
        }
    };
    mutations = {
        setCurrentPage(state: TagState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: TagState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: TagState, tag: Tag) {
            state.tag = tag;
        }
    }
}

const tagModule = new TagModule();
export default tagModule;
