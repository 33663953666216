import {Store, Module, ActionContext} from 'vuex'
import ListModule from './list-module'
import ListState from './list-state'
import AirlineCode from '../entities/airlinecode'
import AirlineCodeVm from '../entities/airlinecodevm'
import Ajax from '../../lib/ajax'
import ListMutations from './list-mutations'
import PageResult from "../entities/page-result";
import {Vue} from "vue-property-decorator";
import LookUp from "@/store/entities/lookup";

interface AirlineCodeState extends ListState<AirlineCodeVm> {
    editAirlineCode: AirlineCode,
}

class AirlineCodeMutations extends ListMutations<AirlineCode> {

}

class AirlineCodeModule extends ListModule<AirlineCodeState, any, AirlineCodeVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<AirlineCodeVm>(),
        loading: false,
        editAirlineCode: new AirlineCode()
    }
    actions = {
        async getAll(context: ActionContext<AirlineCodeState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/airline-code/get',  payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<AirlineCodeVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<AirlineCodeState, any>, payload: any) {
            await Ajax.post('/api/airline-code', payload.data);
        },
        async update(context: ActionContext<AirlineCodeState, any>, payload: any) {
            await Ajax.put('/api/airline-code', payload.data);
        },
        async delete(context: ActionContext<AirlineCodeState, any>, payload: any) {
            await Ajax.delete('/api/airline-code?Id=' + payload.data.id);
        },
        async get(context: ActionContext<AirlineCodeState, any>, payload: any) {
            let response = await Ajax.get('/api/airline-code/' + payload.data);
            return response.data.result as AirlineCode;
        },
        async lookUp(context: ActionContext<AirlineCodeState, any>, payload: any) {
            let response = await Ajax.get('api/dictionary/airline-code', {params: payload});
            return response.data.result as Array<LookUp>;
        },
        async uploadFile(context: ActionContext<AirlineCodeState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.put('/api/airline-code/sync', payload.data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                vm.$Modal.success({title: 'Successfully synced'})
            });
        }
    };
    mutations = {
        setCurrentPage(state: AirlineCodeState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: AirlineCodeState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: AirlineCodeState, airlineCode: AirlineCode) {
            state.editAirlineCode = airlineCode;
        }
    }
}

const airlineCodeModule = new AirlineCodeModule();
export default airlineCodeModule;
