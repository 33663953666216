import {Store, Module, ActionContext} from 'vuex'
import ListModule from '.././list-module'
import ListState from '.././list-state'
import Company from '../../entities/companycontext/company'
import CompanyVm from '../../entities/companycontext/companyvm'
import Ajax from '../../../lib/ajax'
import ListMutations from '.././list-mutations'
import PageResult from '@/store/entities/page-result';
import LookUp from "@/store/entities/lookup";
import Vue from "vue";


interface CompanyState extends ListState<CompanyVm> {
    editCompany: Company,
}

class CompanyMutations extends ListMutations<Company> {

}

class CompanyModule extends ListModule<CompanyState, any, CompanyVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<CompanyVm>(),
        loading: false,
        editCompany: new Company()
    }
    actions = {
        async getAll(context: ActionContext<CompanyState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/company/get', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<CompanyVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<CompanyState, any>, payload: any) {
            let response = await Ajax.post('/api/company', payload.data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data.result;
        },
        async update(context: ActionContext<CompanyState, any>, payload: any) {
            await Ajax.put('/api/company', payload.data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },
        async delete(context: ActionContext<CompanyState, any>, payload: any) {
            await Ajax.delete('api/company?Id=' + payload.data.id);
        },
        async get(context: ActionContext<CompanyState, any>, payload: any) {
            let response = await Ajax.get('api/company/' + payload.data);
            return response.data.result as Company;
        },
        async filteredLookUp(context: ActionContext<CompanyState, any>, payload: any) {
            let response = await Ajax.get('api/company/look-up', {params: payload.data});
            return response.data.result as Array<LookUp>;
        },
        async lookUp(context: ActionContext<CompanyState, any>) {
            let response = await Ajax.get('api/dictionary/company');
            return response.data.result as Array<LookUp>;
        },
        async keyWordLookUp(context: ActionContext<CompanyState, any>, payload: any) {
            let response = await Ajax.get('api/dictionary/company-keyword', {params: payload});
            return response.data.result as Array<LookUp>;
        },
        async jobKeyWordLookUp(context: ActionContext<CompanyState, any>, payload: any) {
            let response = await Ajax.get('api/dictionary/job-company-keyword', {params: payload});
            return response.data.result;
        },
        async customerCompany(context: ActionContext<CompanyState, any>) {
            let response = await Ajax.get('api/dictionary/customer-company');
            return response.data.result as Array<LookUp>;
        },
        async getRelatedCompaniesByCategory(context: ActionContext<CompanyState, any>, payload: any) {
            console.log(payload);
            let response = await Ajax.get('api/dictionary/related-companies-by-category', {params: payload});
            return response.data.result as Array<LookUp>;
        },
        async relatedCompanies(context: ActionContext<CompanyState, any>, payload: any) {
            let response = await Ajax.get('api/dictionary/related-company/' + payload);
            return response.data.result as Array<LookUp>;
        },
        async mailLookUp(context: ActionContext<CompanyState, any>, payload: any) {
            let response = await Ajax.get('api/dictionary/mail-companies?keyWord=' + payload);
            return response.data.result as Array<LookUp>;
        },
        async getContacts(context: ActionContext<CompanyState, any>, payload: any) {
            let response = await Ajax.get('api/contact/company-contacts?companyId=' + payload);
            return response.data.result as Array<LookUp>;
        },
        async getCompaniesKeyWordLookUp(context: ActionContext<CompanyState, any>, payload: any) {
            let response = await Ajax.get('api/dictionary/company-keyword-lookup?keyword=' + payload);
            return response.data.result as Array<LookUp>;
        },
        async uploadFile(context: ActionContext<CompanyState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.put('api/company/sync', payload.data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                vm.$Modal.success({title: 'Successfully synced'})
            });
        }
    };
    mutations = {
        setCurrentPage(state: CompanyState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: CompanyState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: CompanyState, company: Company) {
            state.editCompany = company;
        }
    }
}

const companyModule = new CompanyModule();
export default companyModule;
