import axios from 'axios'
import appconst from './appconst'
import Vue from 'vue'
import qs from 'query-string';
import dayjs from "dayjs";

const ajax = axios.create({
    baseURL: appconst.remoteServiceBaseUrl,
    timeout: 50000
});

ajax.interceptors.request.use(function (config) {
    handleDates(config.data);

    function handleDates(body: any) {
        if (body === null || body === undefined || typeof body !== "object")
            return body;
        for (const key of Object.keys(body)) {
            const value = body[key];
            if (isDate(value)) {
                body[key] = parseUTC(value);
            } else if (typeof value === "object") handleDates(value);
        }
    }

    function isDate(value: any): boolean {
        return value && value instanceof Date;
    }

    function parseUTC(value: Date) {
        return new Date(value.getTime() - value.getTimezoneOffset() * 60000).toISOString().replace('Z', '');
    }

    if (!!window.abp.auth.getToken()) {
        config.headers.common["Authorization"] = "Bearer " + window.abp.auth.getToken();
    }
    config.headers.common[".AspNetCore.Culture"] = window.abp.utils.getCookieValue("Abp.Localization.CultureName");
    config.headers.common["Abp.TenantId"] = window.abp.multiTenancy.getTenantIdCookie();
    return config;
}, function (error) {

    return Promise.reject(error);
});
let vm = new Vue({});
ajax.interceptors.response.use((response) => {
    return response
}, (error) => {
    if (error.response.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        location.href = '/login';
    }
    if (!!error.response && !!error.response.data.error && !!error.response.data.error.message && error.response.data.error.details) {
        vm.$Modal.error({title: error.response.data.error.message, content: error.response.data.error.details})
    } else if (!!error.response && !!error.response.data.error && !!error.response.data.error.message) {
        vm.$Modal.error({
            title: window.abp.localization.localize("Error"),
            content: error.response.data.error.message
        })
    } else if (!error.response) {
        vm.$Modal.error(window.abp.localization.localize('UnknownError'));
    }
    setTimeout(() => {
        vm.$Message.destroy();
    }, 1000);
    return Promise.reject(error);
})
export default ajax;
