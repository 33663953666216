import {Store, Module, ActionContext} from 'vuex'
import ListModule from '.././list-module'
import ListState from '.././list-state'
import CompanyRelationship from '../../entities/companycontext/companyrelationship'
import CompanyRelationshipVm from '../../entities/companycontext/companyrelationshipvm'
import Ajax from '../../../lib/ajax'
import ListMutations from '.././list-mutations'
import PageResult from '@/store/entities/page-result';


interface CompanyRelationshipState extends ListState<CompanyRelationshipVm> {
    editCompanyRelationship: CompanyRelationship,
}

class CompanyRelationshipMutations extends ListMutations<CompanyRelationship> {

}

class CompanyRelationshipModule extends ListModule<CompanyRelationshipState, any, CompanyRelationshipVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<CompanyRelationshipVm>(),
        loading: false,
        editCompanyRelationship: new CompanyRelationship()
    }
    actions = {
        async getAll(context: ActionContext<CompanyRelationshipState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/company-relationship', {params: payload.data});
            context.state.loading = false;
            let page = response.data.result as PageResult<CompanyRelationshipVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<CompanyRelationshipState, any>, payload: any) {
            await Ajax.post('/api/company-relationship', payload.data);
        },
        async update(context: ActionContext<CompanyRelationshipState, any>, payload: any) {
            await Ajax.put('/api/company-relationship', payload.data);
        },
        async delete(context: ActionContext<CompanyRelationshipState, any>, payload: any) {
            await Ajax.delete('api/company-relationship?Id=' + payload.data.id);
        },
        async get(context: ActionContext<CompanyRelationshipState, any>, payload: any) {
            let response = await Ajax.get('api/company-relationship/' + payload.data);
            return response.data.result as CompanyRelationship;
        }
    };
    mutations = {
        setCurrentPage(state: CompanyRelationshipState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: CompanyRelationshipState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: CompanyRelationshipState, companyRelationship: CompanyRelationship) {
            state.editCompanyRelationship = companyRelationship;
        }
    }
}

const companyRelationshipModule = new CompanyRelationshipModule();
export default companyRelationshipModule;