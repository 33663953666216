import {Store, Module, ActionContext} from 'vuex'
import ListModule from '.././list-module'
import ListState from '.././list-state'
import Action from '../../entities/jobcontext/jobaction'
import Ajax from '../../../lib/ajax'
import PageResult from "../../entities/page-result";
import JobAction from "../../entities/jobcontext/jobaction";


interface JobActionState extends ListState<Action> {
    editJobAction: JobAction,
}

class JobActionModule extends ListModule<JobActionState, any, Action> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<Action>(),
        loading: false,
        editJobAction: new Action()
    }
    actions = {
        async getAll(context: ActionContext<JobActionState, any>, payload: any) {
            let response = await Ajax.get('/api/job-action?jobId=' + payload);
            return response.data.result as Array<JobAction>;
        },
        async create(context: ActionContext<JobActionState, any>, payload: any) {
            await Ajax.put('/api/job-action', payload.data);
        }
    };
    mutations = {
        setCurrentPage(state: JobActionState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: JobActionState, pageSize: number) {
            state.pageSize = pageSize;
        },
        edit(state: JobActionState, action: Action) {
            state.editJobAction = action;
        }
    }
}

const jobActionModule = new JobActionModule();
export default jobActionModule;
