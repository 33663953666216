import Entity from '../entity'
import DocumentDynamicProperty from "@/store/entities/jobcontext/documentdynamicproperty";
import DocumentPage from "@/store/entities/jobcontext/documentpage";

export default class Document extends Entity<number> {
    name: string;
    isAutoGenerated: boolean;
    isUsedInShippingOrder: boolean;
    dynamicProperties: Array<DocumentDynamicProperty>;
    pages: Array<DocumentPage>;
    code: string;
    isConsolidationLevel: boolean;
    isPerContainer: boolean;
    isAvailableForClient: boolean;
    requestFrom: Array<number>;
    orientation: number;
}
