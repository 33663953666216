import {Store, Module, ActionContext} from 'vuex'
import ListModule from './list-module'
import ListState from './list-state'
import Incoterm from '../entities/incoterm'
import IncotermLookUp from "../entities/incotermlookup";
import Ajax from '../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from './list-mutations'

interface IncotermState extends ListState<Incoterm> {
    editIncoterm: Incoterm,
}

class IncotermMutations extends ListMutations<Incoterm> {

}

class IncotermModule extends ListModule<IncotermState, any, Incoterm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<Incoterm>(),
        loading: false,
        editIncoterm: new Incoterm()
    }
    actions = {
        async getAll(context: ActionContext<IncotermState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('api/incoterm/get', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<Incoterm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<IncotermState, any>, payload: any) {
            await Ajax.post('api/incoterm', payload.data);
        },
        async update(context: ActionContext<IncotermState, any>, payload: any) {
            await Ajax.put('api/incoterm', payload.data);
        },
        async delete(context: ActionContext<IncotermState, any>, payload: any) {
            await Ajax.delete('api/incoterm?Id=' + payload.data.id);
        },
        async get(context: ActionContext<IncotermState, any>, payload: any) {
            let response = await Ajax.get('api/incoterm/' + payload.id);
            return response.data.result as Incoterm;
        },
        async lookUp(context: ActionContext<IncotermState, any>, payload: any) {
            let response = await Ajax.get('api/dictionary/incoterm');
            return response.data.result as Array<IncotermLookUp>;
        }
    };
    mutations = {
        setCurrentPage(state: IncotermState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: IncotermState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: IncotermState, incoterm: Incoterm) {
            state.editIncoterm = incoterm;
        }
    }
}

const incotermModule = new IncotermModule();
export default incotermModule;