import {ActionContext} from 'vuex'
import ListModule from './list-module'
import ListState from './list-state'
import Currency from '../entities/currency'
import Ajax from '../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from './list-mutations'
import LookUp from "@/store/entities/lookup";

interface CurrencyState extends ListState<Currency> {
    editCurrency: Currency,
}

class CurrencyMutations extends ListMutations<Currency> {

}

class CurrencyModule extends ListModule<CurrencyState, any, Currency> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<Currency>(),
        loading: false,
        editCountry: new Currency()
    }
    actions = {
        async getAll(context: ActionContext<CurrencyState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('api/currency/get',  payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<Currency>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<CurrencyState, any>, payload: any) {
            await Ajax.post('api/currency', payload.data);
        },
        async update(context: ActionContext<CurrencyState, any>, payload: any) {
            await Ajax.put('api/currency', payload.data);
        },
        async delete(context: ActionContext<CurrencyState, any>, payload: any) {
            await Ajax.delete('api/currency?Id=' + payload.data.id);
        },
        async get(context: ActionContext<CurrencyState, any>, payload: any) {
            let response = await Ajax.get('api/currency/' + payload.id);
            return response.data.result as Currency;
        },
        async lookUp(context: ActionContext<CurrencyState, any>) {
            let response = await Ajax.get('api/dictionary/currencies');
            return response.data.result as Array<LookUp>;
        },
    };
    mutations = {
        setCurrentPage(state: CurrencyState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: CurrencyState, pageSize: number) {
            state.pageSize = pageSize;
        },
        edit(state: CurrencyState, currency: Currency) {
            state.editCurrency = currency;
        }
    }
}

const currencyModule = new CurrencyModule();
export default currencyModule;
