import {Store, Module, ActionContext} from 'vuex'
import ListModule from './list-module'
import ListState from './list-state'
import VesselType from '../entities/vesseltype'
import VesselTypeLookUp from "../entities/vesseltypelookup";
import Ajax from '../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from './list-mutations'
import Vue from "vue";

interface VesselTypeState extends ListState<VesselType> {
    editVesselType: VesselType,
}

class UserMutations extends ListMutations<VesselType> {

}

class VesselTypeModule extends ListModule<VesselTypeState, any, VesselType> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<VesselType>(),
        loading: false,
        editVesselType: new VesselType()
    }
    actions = {
        async getAll(context: ActionContext<VesselTypeState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('api/vessel-type/get', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<VesselType>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<VesselTypeState, any>, payload: any) {
            await Ajax.post('api/vessel-type', payload.data);
        },
        async update(context: ActionContext<VesselTypeState, any>, payload: any) {
            await Ajax.put('api/vessel-type', payload.data);
        },
        async delete(context: ActionContext<VesselTypeState, any>, payload: any) {
            await Ajax.delete('api/vessel-type?Id=' + payload.data.id);
        },
        async get(context: ActionContext<VesselTypeState, any>, payload: any) {
            let response = await Ajax.get('api/vessel-type/' + payload.id);
            return response.data.result as VesselType;
        },
        async lookUp(context: ActionContext<VesselTypeState, any>, payload: any) {
            let response = await Ajax.get('api/vessel-type/look-up');
            return response.data.result as Array<VesselTypeLookUp>;
        },
        async uploadFile(context: ActionContext<VesselTypeState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.put('api/vessel-type/sync', payload.data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                vm.$Modal.success({title: 'Successfully synced'})
            });
        }
    };
    mutations = {
        setCurrentPage(state: VesselTypeState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: VesselTypeState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: VesselTypeState, vesselType: VesselType) {
            state.editVesselType = vesselType;
        }
    }
}

const vesselTypeModule = new VesselTypeModule();
export default vesselTypeModule;