import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
import app from './modules/app'
import session from './modules/session'
import account from './modules/account'
import user from './modules/user'
import role from './modules/role'
import vesseltype from './modules/vesseltype'
import vehicletype from './modules/vehicletype'
import companycontact from "./modules/companycontext/companycontact";
import packagetype from './modules/packagetype'
import containertype from './modules/containercontext/containertype'
import document from './modules/jobcontext/document'
import action from './modules/jobcontext/action'
import auditing from './modules/auditing'
import jobAction from './modules/jobcontext/jobaction'
import companyInstruction from './modules/companycontext/companyinstruction'
import jobTemplate from './modules/jobcontext/jobtemplate'
import node from './modules/jobcontext/node'
import jobNode from './modules/jobcontext/jobnode'
import containersize from './modules/containercontext/containersize'
import isocode from './modules/containercontext/isocode'
import container from './modules/containercontext/container'
import order from './modules/ordercontext/order'
import job from './modules/jobcontext/job'
import notification from './modules/notification'
import fieldMetadata from './modules/jobcontext/fieldmetadata'
import category from './modules/companycontext/category'
import department from './modules/companycontext/department'
import contact from './modules/companycontext/contact'
import address from './modules/companycontext/address'
import jobDocumentRequest from './modules/jobcontext/jobdocumentrequest'
import companyRelationship from './modules/companycontext/companyrelationship'
import company from './modules/companycontext/company'
import companyAction from './modules/companycontext/companyaction'
import servicelevel from './modules/servicelevel'
import vessel from './modules/vessel'
import tradetransportlocation from './modules/countrycontext/tradetransportlocation'
import tenant from './modules/tenant'
import subdivision from "./modules/subdivision";
import country from "./modules/country";
import subdivisiontype from "./modules/subdivisiontype";
import incoterm from "./modules/incoterm";
import airlinecode from "./modules/airlinecode"
import emailTemplate from "./modules/notificationcontext/emailtemplate"
import tag from "./modules/jobcontext/tag"
import jobOverview from "./modules/jobcontext/joboverview";
import currency from "./modules/currency";

const store = new Vuex.Store({
    state: {
        //
    },
    mutations: {
        //
    },
    actions: {},
    modules: {
        app,
        session,
        account,
        user,
        role,
        notification,
        tenant,
        fieldMetadata,
        companyAction,
        jobDocumentRequest,
        job,
        companyInstruction,
        order,
        country,
        currency,
        subdivision,
        jobTemplate,
        jobNode,
        tag,
        jobOverview,
        emailTemplate,
        auditing,
        vesseltype,
        document,
        tradetransportlocation,
        vessel,
        action,
        jobAction,
        node,
        companycontact,
        servicelevel,
        vehicletype,
        packagetype,
        incoterm,
        containertype,
        airlinecode,
        containersize,
        isocode,
        container,
        category,
        department,
        contact,
        subdivisiontype,
        company,
        address,
        companyRelationship
    }
});

export default store;
