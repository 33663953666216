import {Store, Module, ActionContext} from 'vuex'
import ListState from '.././list-state'
import JobNodeVm from '../../entities/jobcontext/jobnodevm'
import Ajax from '../../../lib/ajax'
import ListModule from "@/store/modules/list-module";


interface JobNodeState extends ListState<JobNodeVm> {
    jobId: number;
    fullList: Array<JobNodeVm>
}

class JobNodeModule extends ListModule<JobNodeState, any, JobNodeVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<JobNodeVm>(),
        fullList: new Array<JobNodeVm>(),
        loading: false,
        jobId: 0
    }
    actions = {
        async getAll(context: ActionContext<JobNodeState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/job-node?jobId=' + payload);
            context.state.loading = false;
            context.state.fullList = response.data.result as Array<JobNodeVm>;
            return response.data.result as Array<JobNodeVm>;
        },
        async getRelated(context: ActionContext<JobNodeState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/job-node/related?jobId=' + payload);
            context.state.loading = false;
            context.state.list = response.data.result as Array<JobNodeVm>;
            return response.data.result as Array<JobNodeVm>;
        },
        async update(context: ActionContext<JobNodeState, any>, payload: any) {
            await Ajax.put('/api/job-node', payload.data);
        }
    };
    mutations = {
        setJobId(state: JobNodeState, jobId: number) {
            state.jobId = jobId;
        }, setCurrentPage(state: JobNodeState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: JobNodeState, pageSize: number) {
            state.pageSize = pageSize;
        },
        edit(state: JobNodeState, jobId: number) {
            state.jobId = jobId;
        }
    }
}

const jobNodeModule = new JobNodeModule();
export default jobNodeModule;
