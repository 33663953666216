import {Store, Module, ActionContext} from 'vuex'
import ListModule from '.././list-module'
import ListState from '.././list-state'
import Category from '../../entities/companycontext/category'
import CategoryVm from '../../entities/companycontext/categoryvm'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '.././list-mutations'
import CategoryLookUp from "@/store/entities/companycontext/categorylookup";

interface CategoryState extends ListState<CategoryVm> {
    editCategory: Category,
}

class CategoryMutations extends ListMutations<Category> {

}

class CategoryModule extends ListModule<CategoryState, any, CategoryVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<CategoryVm>(),
        loading: false,
        editCategory: new Category()
    }
    actions = {
        async getAll(context: ActionContext<CategoryState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/category/get', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<CategoryVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<CategoryState, any>, payload: any) {
            await Ajax.post('/api/category', payload.data);
        },
        async update(context: ActionContext<CategoryState, any>, payload: any) {
            await Ajax.put('/api/category', payload.data);
        },
        async delete(context: ActionContext<CategoryState, any>, payload: any) {
            await Ajax.delete('api/category?Id=' + payload.data.id);
        },
        async get(context: ActionContext<CategoryState, any>, payload: any) {
            let response = await Ajax.get('api/category/' + payload.data);
            return response.data.result as Category;
        },
        async lookUp(context: ActionContext<CategoryState, any>) {
            let response = await Ajax.get('/api/dictionary/company-categories');
            return response.data.result as Array<CategoryLookUp>;
        },
        async byCode(context: ActionContext<CategoryState, any>, payload: any) {
            let response = await Ajax.get('api/dictionary/company-category-by-code?code=' + payload);
            return response.data.result as Category;
        }
    };
    mutations = {
        setCurrentPage(state: CategoryState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: CategoryState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: CategoryState, category: Category) {
            state.editCategory = category;
        }
    }
}

const categoryModule = new CategoryModule();
export default categoryModule;
