import {Store, Module, ActionContext} from 'vuex'
import ListModule from '.././list-module'
import ListState from '.././list-state'
import CompanyAction from '../../entities/companycontext/companyaction'
import CompanyActionVm from '../../entities/companycontext/companyactionvm'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '.././list-mutations'
import LookUp from "@/store/entities/lookup";

interface CompanyActionState extends ListState<CompanyActionVm> {
    companyId: number;
}

class CompanyContactMutations extends ListMutations<CompanyAction> {
}

class CompanyActionModule extends ListModule<CompanyActionState, any, CompanyActionVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<CompanyActionVm>(),
        loading: false,
        companyId: 0
    }
    actions = {
        async getAll(context: ActionContext<CompanyActionState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/company-action/get', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<CompanyActionVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<CompanyActionState, any>, payload: any) {
            await Ajax.post('/api/company-action', payload.data);
        },
        async getCompanyActionTypes(context: ActionContext<CompanyActionState, any>) {
            let response = await Ajax.get('/api/dictionary/company-action-type');
            return response.data.result as Array<LookUp>;
        }
    };
    mutations = {
        setCurrentPage(state: CompanyActionState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: CompanyActionState, pageSize: number) {
            state.pageSize = pageSize;
        },
        setCompanyId(state: CompanyActionState, companyId: number) {
            state.companyId = companyId;
        },
    }
}

const companyActionModule = new CompanyActionModule();
export default companyActionModule;
