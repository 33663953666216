import {Store, Module, ActionContext} from 'vuex'
import ListModule from '.././list-module'
import ListState from '.././list-state'
import ContainerSize from '../../entities/containercontext/containersize'
import ContainerSizeVm from '../../entities/containercontext/containersizevm'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '.././list-mutations'
import ContainerSizeLookUp from "@/store/entities/containercontext/contzinersizelookup";
import Vue from "vue";
import LookUp from "@/store/entities/lookup";

interface ContainerSizeState extends ListState<ContainerSizeVm> {
    editContainerSize: ContainerSize,
}

class UserMutations extends ListMutations<ContainerSize> {

}

class ContainerSizeModule extends ListModule<ContainerSizeState, any, ContainerSizeVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<ContainerSizeVm>(),
        loading: false,
        editSubdivision: new ContainerSize()
    }
    actions = {
        async getAll(context: ActionContext<ContainerSizeState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/container-size/get', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<ContainerSizeVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<ContainerSizeState, any>, payload: any) {
            await Ajax.post('/api/container-size', payload.data);
        },
        async update(context: ActionContext<ContainerSizeState, any>, payload: any) {
            await Ajax.put('/api/container-size', payload.data);
        },
        async delete(context: ActionContext<ContainerSizeState, any>, payload: any) {
            await Ajax.delete('api/container-size?Id=' + payload.data.id);
        },
        async get(context: ActionContext<ContainerSizeState, any>, payload: any) {
            let response = await Ajax.get('api/container-size/' + payload.data);
            return response.data.result as ContainerSize;
        },
        async lookUp(context: ActionContext<ContainerSizeState, any>, payload: any) {
            let response = await Ajax.get('/api/dictionary/container-size');
            return response.data.result as Array<LookUp>;
        },
        async uploadFile(context: ActionContext<ContainerSizeState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.put('/api/container-size/sync', payload.data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                vm.$Modal.success({title: 'Successfully synced'})
            });
        }
    };
    mutations = {
        setCurrentPage(state: ContainerSizeState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: ContainerSizeState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: ContainerSizeState, containerSize: ContainerSize) {
            state.editContainerSize = containerSize;
        }
    }
}

const containerSizeModule = new ContainerSizeModule();
export default containerSizeModule;
