import {Store, Module, ActionContext} from 'vuex'
import ListModule from './list-module'
import ListState from './list-state'
import Country from '../entities/country'
import CountryLookUp from "../entities/countryLookUp";
import Ajax from '../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from './list-mutations'
import Vue from "vue";
import LookUp from "@/store/entities/lookup";
import NotificationVm from "@/store/entities/notificationcontext/notificationvm";

interface NotificationState extends ListState<NotificationVm> {
}

class NotificationMutations extends ListMutations<NotificationVm> {

}

class NotificationModule extends ListModule<NotificationState, any, NotificationVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<NotificationVm>(),
        loading: false
    }
    actions = {
        async getAll(context: ActionContext<NotificationState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/services/app/NotificationService/user-notifications',  payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<NotificationVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        }
    };
    mutations = {
        setCurrentPage(state: NotificationState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: NotificationState, pageSize: number) {
            state.pageSize = pageSize;
        }
    }
}

const notificationModule = new NotificationModule();
export default notificationModule;
